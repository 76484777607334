<template>
  <div class="intro-page">
    <div class="intro-container">
      <h1 class="intro-title">2024年员工心理健康测评</h1>
      <div class="content1">亲爱的同事：<br /></div>

      <div class="content">
        心理健康是我们幸福生活和快乐工作的基石。为了让大家更好地了解自己的心理状态，以更积极的心态面对工作与生活中的挑战，同时让“健康加油站”项目能够为大家提供更加精准化的身心健康支持，特邀请大家参与本次心理健康测评。
      </div>
      <br />
      <div class="content">
        完成本次测评需要10-15分钟时间，所有数据结果均由第三方专业机构收集，个体报告结果只能由本人查看，<span
          class="jiacu"
          >测评过程会严格保护您的个人隐私</span
        >
        ，请放心作答。
      </div>

      <div class="btn">
        <button class="start-button" @click="goguide">测评结果</button>
        <button class="start-button" @click="startAssessment">开始测评</button>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";

export default {
  methods: {
    startAssessment() {
      const userId = localStorage.getItem("userId");

      if (!userId || userId == "undefined") {
        Toast("请先登录");
        setTimeout(() => {
          this.$router.push("/cp/chat/login");
        }, 1000);
        return;
      }
      // 跳转到测评页面
      this.$router.push("/CP/chat/answer");
    },
    goguide() {
      this.$router.push("/CP/chat/Reportlist");
    },
  },
};
</script>

<style scoped>
/* 引导页整体布局 */
.intro-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f9f9f9;
  padding: 20px;
  box-sizing: border-box;
  /* background-image: url("https://lesson.iapeap.com/images/cp/wuzi.png");
  background-size: cover;
  background-position: center; */
  max-width: 400px;
  margin: 0 auto;
}

.intro-container {
  max-width: 720px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 30px 20px;
  box-sizing: border-box;
  text-align: center;
}

/* 标题样式 */
.intro-title {
  font-size: 1.8em;
  color: #333;
  margin-bottom: 20px;
  font-weight: bold;
}

/* 正文内容样式 */
.intro-text {
  line-height: 1.8;
  color: #333;
  text-align: left;
  margin-bottom: 10px;
}

/* 按钮样式 */
.start-button {
  background-color: #76c7c0;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.start-button:hover {
  background-color: #5ba9a1;
}

/* 响应式处理 */
@media (max-width: 768px) {
  .intro-container {
    padding: 20px;
  }
  .intro-title {
    font-size: 1.5em;
  }
  .start-button {
    padding: 8px 16px;
  }
}
.content {
  text-align: left;
  text-indent: 20px;
}
.content1 {
  text-align: left;
  margin-bottom: 20px;
}
.jiacu {
  /* 加粗 */
  font-weight: bold;
}
.btn {
  display: flex;
  justify-content: center;
  align-content: center;
  gap: 20px;
}
</style>
