import Vue from "vue";
import VueRouter from "vue-router";
import layout from "../layout/index.vue";
Vue.use(VueRouter);
//
const routes = [
  {
    path: "/",
    component: layout,
    children: [
      {
        path: "/",
        name: "home",

        component: () => import("../views/index.vue"),
        meta: { title: "首页" },
      },
    ],
  },
  {
    path: "/login",
    component: layout,
    name: "登录",
    component: () => import("../views/login/login.vue"),
  },
  {
    path: "/updatePassWord",
    component: layout,
    name: "修改密码",
    component: () => import("../views/login/updatePassWord.vue"),
  },
  {
    path: "/register",
    component: layout,
    name: "注册账号",
    component: () => import("../views/login/register.vue"),
  },
  {
    path: "/test/testStart",
    component: layout,
    name: "开始答题",
    component: () => import("../views/test/testStart.vue"),
  },
  {
    path: "/test/worth",
    component: layout,
    name: "测试欢迎主页",
    component: () => import("../views/test/worth.vue"),
  },
  {
    path: "/test/worthtest",
    component: layout,
    name: "测试主页",
    component: () => import("../views/test/worthtest.vue"),
  },
  {
    path: "/test/testresult",
    component: layout,
    name: "测验结果",
    component: () => import("../views/test/testresult.vue"),
  },
  {
    path: "/capsule/capsule",
    component: layout,
    name: "情绪胶囊",
    component: () => import("../views/capsule/capsule.vue"),
  },
  {
    path: "/circle/circle",
    component: layout,
    name: "圈子",
    component: () => import("../views/circle/circle.vue"),
  },
  {
    path: "/evaluating/record",
    component: layout,
    name: "测评记录",
    component: () => import("../views/evaluating/record.vue"),
  },
  {
    path: "/medals/medal",
    component: layout,
    name: "勋章",
    component: () => import("../views/medals/medal.vue"),
  },
  {
    path: "/user/usermsg",
    component: layout,
    name: "个人信息",
    component: () => import("../views/user/usermsg.vue"),
  },
  {
    path: "/report/stage",
    component: layout,
    name: "阶段报告",
    component: () => import("../views/report/stage.vue"),
  },
  {
    path: "/practice/achievement",
    component: layout,
    name: "练习成果",
    component: () => import("../views/practice/achievement.vue"),
  },
  {
    path: "/practice/file",
    component: layout,
    name: "练习档案",
    component: () => import("../views/practice/file.vue"),
  },
  {
    path: "/practice/like",
    component: layout,
    name: "喜欢的练习",
    component: () => import("../views/practice/like.vue"),
  },
  {
    path: "/report/fillIn",
    component: layout,
    name: "填写勋章",
    component: () => import("../views/report/fillIn.vue"),
  },
  {
    path: "/setout/index",
    component: layout,
    name: "今日练习",
    component: () => import("../views/setout/index.vue"),
  },
  {
    path: "/setout/handbook",
    component: layout,
    name: "入营指南",
    component: () => import("../views/setout/handbook.vue"),
  },
  {
    path: "/setout/mepromise",
    component: layout,
    name: "对自己的承诺",
    component: () => import("../views/setout/mepromise.vue"),
  },
  {
    path: "/setout/signature",
    component: layout,
    name: "签署承诺书",
    component: () => import("../views/setout/signature.vue"),
  },
  {
    path: "/setout/ninjia",
    component: layout,
    name: "情绪小练习",
    component: () => import("../views/setout/ninjia.vue"),
  },
  {
    path: "/setout/ckninjia",
    component: layout,
    name: "查看小练习",
    component: () => import("../views/setout/ckninjia.vue"),
  },
  {
    path: "/setout/assistant",
    component: layout,
    name: "练习助手",
    component: () => import("../views/setout/assistant.vue"),
  },
  {
    path: "/emotion/diary",
    component: layout,
    name: "情绪日记",
    component: () => import("../views/emotion/diary.vue"),
  },
  {
    path: "/emotion/ckdiary",
    component: layout,
    name: "查看情绪日记",
    component: () => import("../views/emotion/ckdiary.vue"),
  },
  {
    path: "/setout/check",
    component: layout,
    name: "查看承诺书",
    component: () => import("../views/setout/check.vue"),
  },
  {
    path: "/emotion/SevenDay",
    component: layout,
    name: "7天情绪坐标",
    component: () => import("../views/emotion/SevenDay.vue"),
  },
  {
    path: "/setout/practicelist",
    component: layout,
    name: "练习列表",
    component: () => import("../views/setout/practicelist.vue"),
  },

  {
    path: "/setout/trainingground",
    component: layout,
    name: "情绪训练场",
    component: () => import("../views/setout/trainingground.vue"),
  },
  {
    path: "/setout/traininggroundItem",
    component: layout,
    name: "情景训练场选择",
    component: () => import("../views/setout/traininggroundItem.vue"),
  },
  {
    path: "/test",
    component: layout,
    name: "宝洁能量测评",
    component: () => import("../views/baojie/test.vue"),
  },
  {
    path: "/result",
    component: layout,
    name: "宝洁能量测评报告",
    component: () => import("../views/baojie/result.vue"),
  },
  {
    path: "/welcome",
    component: layout,
    name: "宝洁指导语",
    component: () => import("../views/baojie/welcome.vue"),
  },
  {
    path: "/testH",
    component: layout,
    name: "后测能量测评",
    component: () => import("../views/HouCe/test.vue"),
  },
  {
    path: "/resultH",
    component: layout,
    name: "后测能量测评报告",
    component: () => import("../views/HouCe/result.vue"),
  },
  {
    path: "/welcomeH",
    component: layout,
    name: "后测指导语",
    component: () => import("../views/HouCe/welcome.vue"),
  },
  {
    path: "/HouCe/comparison",
    component: layout,
    name: "后侧对比报告",
    component: () => import("../views/HouCe/comparison.vue"),
  },
  {
    path: "/CP/minsheng/login",
    component: layout,
    name: "测评登录",
    component: () => import("../CP/minsheng/login.vue"),
  },

  {
    path: "/CP/minsheng/password",
    component: layout,
    name: "修改密码",
    component: () => import("../CP/minsheng/password.vue"),
  },
  {
    path: "/CP/minsheng/answer",
    component: layout,
    name: "测评",
    component: () => import("../CP/minsheng/answer.vue"),
  },
  {
    path: "/CP/minsheng/guide",
    component: layout,
    name: "引导语",
    component: () => import("../CP/minsheng/guide.vue"),
  },
  {
    path: "/CP/minsheng/Report",
    component: layout,
    name: "报告结果",
    component: () => import("../CP/minsheng/Report.vue"),
  },
  {
    path: "/CP/minsheng/answerOver",
    component: layout,
    name: "查看报告",
    component: () => import("../CP/minsheng/answerOver.vue"),
  },
  // 英大
  {
    path: "/CP/yingda/login",
    component: layout,
    name: "英大测评登录",
    component: () => import("../CP/yingda/login.vue"),
  },
  {
    path: "/CP/yingda/answer",
    component: layout,
    name: "英大测评",
    component: () => import("../CP/yingda/answer.vue"),
  },
  {
    path: "/CP/yingda/guide",
    component: layout,
    name: "英大引导语",
    component: () => import("../CP/yingda/guide.vue"),
  },
  {
    path: "/CP/yingda/Report",
    component: layout,
    name: "英大报告结果",
    component: () => import("../CP/yingda/Report.vue"),
  },
  {
    path: "/CP/yingda/answerOver",
    component: layout,
    name: "英大查看报告",
    component: () => import("../CP/yingda/answerOver.vue"),
  }, // 大鹏
  {
    path: "/CP/dapeng/login",
    component: layout,
    name: "大鹏测评登录",
    component: () => import("../CP/dapeng/login.vue"),
  },
  {
    path: "/CP/dapeng/answer",
    component: layout,
    name: "大鹏测评",
    component: () => import("../CP/dapeng/answer.vue"),
  },
  {
    path: "/CP/dapeng/guide",
    component: layout,
    name: "大鹏引导语",
    component: () => import("../CP/dapeng/guide.vue"),
  },
  {
    path: "/CP/dapeng/Report",
    component: layout,
    name: "大鹏报告结果",
    component: () => import("../CP/dapeng/Report.vue"),
  },
  {
    path: "/CP/dapeng/answerOver",
    component: layout,
    name: "大鹏查看报告",
    component: () => import("../CP/dapeng/answerOver.vue"),
  }, // 大鹏
  {
    path: "/CP/chat/login",
    component: layout,
    name: "对话测评登录",
    component: () => import("../CP/chat/login.vue"),
  },
  {
    path: "/CP/chat/answer",
    component: layout,
    name: "对话测评",
    component: () => import("../CP/chat/answer.vue"),
  },
  {
    path: "/CP/chat/list",
    component: layout,
    name: "对话测评列表",
    component: () => import("../CP/chat/list.vue"),
  },
  {
    path: "/CP/chat/guide",
    component: layout,
    name: "对话引导语",
    component: () => import("../CP/chat/guide.vue"),
  },
  {
    path: "/CP/chat/Report",
    component: layout,
    name: "对话报告结果",
    component: () => import("../CP/chat/Report.vue"),
  },
  {
    path: "/CP/chat/answerOver",
    component: layout,
    name: "对话查看报告",
    component: () => import("../CP/chat/answerOver.vue"),
  },
  {
    path: "/CP/chat/Reportlist",
    component: layout,
    name: "对话查看报告列表",
    component: () => import("../CP/chat/Reportlist.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
