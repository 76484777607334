<template>
  <div class="assessment-list">
    <!-- 页面标题 -->
    <h1>问卷列表</h1>
    <p class="sub-title">以下是所有问卷调查，可点击查看详情</p>

    <!-- 问卷列表 -->
    <ul class="evaluation-list">
      <li
        v-for="survey in surveys"
        :key="survey.id"
        @click="navigateToSurvey(survey.id)"
        class="evaluation-item"
      >
        <!-- 问卷图片 -->
        <img :src="survey.img" alt="问卷头像" class="survey-image" />

        <!-- 问卷信息 -->
        <div class="survey-info">
          <h3 class="survey-name">{{ survey.name }}</h3>
          <p class="survey-details">{{ survey.ctx }}</p>
          <div class="survey-stats">
            <span>完成数：{{ survey.complete }}</span>
            <span>题目数量：{{ survey.num }}</span>
          </div>
          <div class="survey-id">ID: {{ survey.id }}</div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      surveys: [
        {
          id: 1,
          name: "想了解自己的焦虑情绪程度？",
          num: "10",
          ctx: "这是关于客户服务满意度的问卷调查。",
          img: "https://today.eapchina.net/upload/Image/mrtp/3077222420.jpeg",
          complete: "999",
        },
        {
          id: 2,
          name: "市场调研分析",
          num: "15",
          ctx: "针对市场趋势和需求的调研问卷。",
          img: "https://today.eapchina.net/upload/Image/mrtp/3077222420.jpeg",
          complete: "786",
        },
        {
          id: 3,
          name: "员工满意度调查",
          num: "12",
          ctx: "了解员工对公司环境和福利的评价。",
          img: "https://today.eapchina.net/upload/Image/mrtp/3077222420.jpeg",
          complete: "852",
        },
      ],
    };
  },
  methods: {
    navigateToSurvey(id) {
      this.$router.push({ path: `/CP/chat/guide/?id=${id}` });
    },
  },
};
</script>

<style scoped>
/* 页面整体样式 */
.assessment-list {
  font-family: Arial, sans-serif;
  margin: 20px auto;
  max-width: 800px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.assessment-list h1 {
  text-align: center;
  color: #333;
  margin-bottom: 10px;
}

.sub-title {
  text-align: center;
  color: #666;
  margin-bottom: 20px;
  font-size: 14px;
}

/* 问卷列表样式 */
.evaluation-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.evaluation-item {
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  margin: 12px 0;
  padding: 15px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.evaluation-item:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* 图片样式 */
.survey-image {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  margin-right: 20px;
}

/* 信息区域 */
.survey-info {
  flex-grow: 1;
}

.survey-name {
  font-weight: bold;
  font-size: 16px;
  color: #333;
}

.survey-details {
  font-size: 14px;
  color: #666;
  margin: 5px 0;
}

.survey-stats {
  font-size: 12px;
  color: #999;
  margin-top: 10px;
}

.survey-stats span {
  margin-right: 15px;
}

.survey-id {
  color: #666;
  font-size: 14px;
  text-align: right;
}
</style>
