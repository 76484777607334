<template>
  <div class="assessment">
    <div class="assessment-container">
      <div class="topmsg">
        <div class="progress-bar">
          <div class="progress-done" :style="{ width: progress + '%' }"></div>
        </div>
        <p class="progress-text">
          共 {{ questions.length }} 题，完成 {{ progress }}%
        </p>
      </div>
      <div class="dialogue">
        <template v-for="(question, index) in questions">
          <div class="message-pair" v-if="index <= currentQuestionIndex">
            <div class="system-message">
              <img
                src="https://today.eapchina.net/upload/Image/mrtp/3077222420.jpeg"
                alt="System"
              />
              <p class="msg">{{ question.subject }}</p>
            </div>
            <div class="user-response">
              <div class="msg2">
                <div v-for="option in question.item" :key="option.id">
                  <input
                    type="radio"
                    :name="'question' + index"
                    :id="'option' + option.id"
                    v-model="responses[index]"
                    :value="option.id"
                    @change="() => handleSelect(index)"
                  />
                  <label :for="'option' + option.id">{{ option.name }}</label>
                </div>
              </div>
              <img
                src="https://img0.baidu.com/it/u=3014819608,3991763416&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500"
                alt="User"
              />
            </div>
          </div>
        </template>
      </div>
      <button v-if="assessmentCompleted" @click="submit" class="submit-button">
        完成测评
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      questions: [],
      responses: [],
      currentQuestionIndex: 0,
      assessmentCompleted: false,
    };
  },
  computed: {
    progress() {
      const completedQuestions = this.responses.filter(
        (response) => response !== ""
      ).length;
      return Math.round((completedQuestions / this.questions.length) * 100);
    },
  },
  methods: {
    fetchQuestions() {
      axios
        .get("http://192.168.1.217:8081/assess/getMeiTuanAssessById?lbId=3475")
        // .get("https://assess.eapchina.net/getMeiTuanAssessById?lbId=3475")

        .then((response) => {
          this.questions = response.data.data.part;
          this.responses = new Array(this.questions.length).fill("");
          this.loadProgress(); // 加载保存的进度
        })
        .catch((error) => console.error("Error fetching questions:", error));
    },

    handleSelect(index) {
      if (index === this.currentQuestionIndex) {
        if (this.currentQuestionIndex < this.questions.length - 1) {
          this.currentQuestionIndex++;
          this.saveProgress(); // 保存进度
          this.$nextTick(() => {
            this.scrollToCurrentQuestion();
          });
        } else {
          this.assessmentCompleted = true;
          this.saveProgress(); // 最后一题也保存进度
          this.$nextTick(() => {
            this.scrollToFinishButton();
          });
        }
      }
    },
    saveProgress() {
      const progressData = {
        responses: this.responses,
        currentQuestionIndex: this.currentQuestionIndex,
        assessmentCompleted: this.assessmentCompleted,
      };
      localStorage.setItem("assessmentProgress", JSON.stringify(progressData));
    },
    loadProgress() {
      const savedProgress = JSON.parse(
        localStorage.getItem("assessmentProgress")
      );
      if (savedProgress) {
        this.responses = savedProgress.responses;
        this.currentQuestionIndex = savedProgress.currentQuestionIndex;
        this.assessmentCompleted = savedProgress.assessmentCompleted;
      }
    },
    scrollToCurrentQuestion() {
      const nextQuestion = this.$el.querySelector(
        `.message-pair:nth-child(${this.currentQuestionIndex + 1})`
      );
      if (nextQuestion) {
        nextQuestion.scrollIntoView({ behavior: "smooth" });
      }
    },
    scrollToFinishButton() {
      const finishButton = this.$el.querySelector(".submit-button");
      if (finishButton) {
        finishButton.scrollIntoView({ behavior: "smooth" });
      }
    },
    async submit() {
      const daan = {};
      this.responses.forEach((response, index) => {
        daan[
          "lb" +
            this.questions[index].lbassessment_id +
            "-" +
            this.questions[index].id
        ] = response;
      });
      console.log("提交的答案：", daan);
      daan["userId"] = window.localStorage.getItem("userId");
      daan["assId"] = "3475";

      try {
        const response = await fetch(
          "http://192.168.1.217:8081/assess/saveMeiTuanAnswer",
          // "https://assess.eapchina.net/saveMeiTuanAnswer",

          {
            method: "POST",
            body: JSON.stringify(daan),
          }
        );
        if (!response.ok) {
          throw new Error(`提交失败，状态码：${response.status}`);
        }
        localStorage.removeItem("assessmentProgress"); // 清除缓存
        const result = await response.json();
        console.log(result.voteLogId);
        // 带一个参数votelogId
        this.$router.push(`/CP/chat/answerOver?votelogId=${result.voteLogId}`);
        localStorage.setItem("voteLogId", result.voteLogId); // 清理缓存数据
      } catch (error) {
        Toast.fail("提交失败");
      }
    },
  },
  created() {
    this.fetchQuestions();
  },
};
</script>

<style>
.assessment {
  background-color: #f7f7f7;
  min-height: 100vh;
  padding-bottom: 20px;
}
.dialogue {
  padding-top: 50px;
}
.start-screen,
.dialogue,
.message-pair {
  display: flex;
  flex-direction: column;
}

.system-message,
.user-response {
  display: flex;
  border-radius: 16px;
  padding: 10px 15px;
  margin-bottom: 10px;
  gap: 10px;
}
.msg {
  background-color: #fff;
  padding: 5px 10px;
  box-sizing: border-box;
  border-radius: 20px;
}
.msg2 {
  background-color: #efefef;
  padding: 5px 10px;
  box-sizing: border-box;
  border-radius: 20px;
}
.msg2 div {
  background-color: #fff;
  margin: 10px 0;
  padding: 5px 10px;
  box-sizing: border-box;
  border-radius: 5px;
}
.system-message {
  align-self: flex-start;
}

.user-response {
  align-self: flex-end;
}

.system-message img,
.user-response img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 10px;
}

input[type="radio"] {
  accent-color: #007aff;
}

label {
  margin: 0 10px;
}
.topmsg {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  font-size: 0.8em;
  position: fixed;
  top: 0;
  width: 100%;
  box-sizing: border-box;
}
.progress-bar {
  width: 60%;
  background-color: #e0e0e0;
  border-radius: 20px;
  height: 10px;
}

.progress-done {
  height: 100%;
  background-color: #007aff;
  border-radius: 20px;
  transition: width 0.4s ease;
}

.progress-text {
  text-align: center;
}
.submit-button {
  display: block; /* 确保按钮为块级元素 */
  width: 50%; /* 按钮宽度，可根据需要调整 */
  height: 50px;
  line-height: 50px; /* 使文字垂直居中 */
  text-align: center; /* 文字水平居中 */
  border: none;
  color: #fff;
  border-radius: 10px;
  margin: 20px auto; /* 上下边距和自动水平居中 */
  background-color: #007aff;
  cursor: pointer; /* 鼠标悬停时显示指针 */
  transition: background-color 0.3s; /* 平滑背景颜色过渡 */
}

.submit-button:hover {
  background-color: #005ecb; /* 鼠标悬停时改变颜色 */
}
</style>
