<template>
  <div class="assessment-list">
    <h1>测评结果列表</h1>
    <p class="sub-title">以下是您的测评报告结果，可点击查看详情</p>
    <ul v-if="assessmentData">
      <li v-for="item in assessmentData" :key="item.id" class="assessment-item">
        <div class="assessment-content">
          <h3>报告编号：{{ item.id }}</h3>
          <p>生成日期：{{ item.date }}</p>
          <p>状态：<span class="status-complete">完成</span></p>
        </div>
        <button class="view-report-btn" @click="goreport(item.id)">
          查看报告
        </button>
      </li>
    </ul>
    <div v-else class="sub-title">未查询到报告结果</div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      assessmentData: [
        { id: 932036, date: "2024-12-20", status: "完成" },
        { id: 930479, date: "2024-12-19", status: "完成" },
        { id: 930486, date: "2024-12-18", status: "完成" },
        { id: 930489, date: "2024-12-17", status: "完成" },
        { id: 930497, date: "2024-12-16", status: "完成" },
      ],
    };
  },
  methods: {
    goreport(id) {
      this.$router.push({ path: "report", query: { voteLogId: id } });
    },
    formatDate(date) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(date).toLocaleDateString("zh-CN", options);
    },
    getgetReportList() {
      const userId = localStorage.getItem("userId");
      axios
        .get("http://192.168.1.217:8081/assess/getReportList", {
          // .get("https://assess.eapchina.net/getReportList", {

          params: { userId },
        })
        .then((response) => {
          const res = response.data;
          this.assessmentData = res.data;
        })
        .catch((error) => {});
    },
    goreport(id) {
      this.$router.push({ path: "report", query: { voteLogId: id } });
    },
  },
  created() {
    this.getgetReportList();
  },
};
</script>

<style scoped>
.assessment-list {
  font-family: Arial, sans-serif;
  margin: 20px auto;
  max-width: 800px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.assessment-list h1 {
  text-align: center;
  color: #333;
  margin-bottom: 10px;
}

.sub-title {
  text-align: center;
  color: #666;
  margin-bottom: 20px;
  font-size: 14px;
}

.assessment-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.assessment-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
  margin: 12px 0;
  padding: 15px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.assessment-item:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.assessment-content {
  flex: 1;
}

.assessment-content h3 {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.assessment-content p {
  margin: 5px 0;
  font-size: 14px;
  color: #666;
}

.status-complete {
  color: #28a745;
  font-weight: bold;
}

.status-pending {
  color: #dc3545;
  font-weight: bold;
}

.view-report-btn {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.view-report-btn:hover {
  background-color: #0056b3;
}
</style>
