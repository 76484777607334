<template>
  <div class="questionnaire">
    <div>
      <!-- <h2 class="title">测评问卷</h2> -->
      <h2
        class="question-title"
        v-if="currentIndex >= '0' && currentIndex < '5'"
      >
        一、请根据您的实际情况选择符合的选项。
      </h2>
      <h2
        class="question-title"
        v-if="currentIndex >= '5' && currentIndex < '25'"
      >
        二、最近一个月，您的感觉是否符合以下选项，答案符合，请选“是”，答案不符合，请选“否”
        。
      </h2>
      <h2
        class="question-title"
        v-if="currentIndex >= '25' && currentIndex < '31'"
      >
        三、最近半年，您有过下列行为吗？请选择对应选项。
      </h2>
      <h2
        class="question-title"
        v-if="currentIndex >= '31' && currentIndex < '36'"
      >
        四、根据题目描述，选择最符合自己情况的选项。
      </h2>
      <h2
        class="question-title"
        v-if="currentIndex > '36' && currentIndex < '38'"
      >
        五、最近半年，您遇到过下列事件吗？请选择对应选项(多选)。
      </h2>

      <h2
        class="question-title"
        v-if="currentIndex >= '38' && currentIndex < '42'"
      >
        六、请根据您工作生活中的实际情况进行作答。
      </h2>
      <h2
        class="question-title"
        v-if="currentIndex >= '42' && currentIndex < '54'"
      >
        七．下面是关于您感受到的社会支持情况的描述，请根据您的情况、选择最符合的选项。
      </h2>
      <h2
        class="question-title"
        v-if="currentIndex >= '54' && currentIndex < '74'"
      >
        八、心理健康素养指运用心理健康知识、技能和态度,
        保持和促进本人及他人心理健康的能力，提升心理健康素养是促进心理健康的重要途径。下面是关于心理健康相关认识的描述，请根据题目描述，结合自己的当前的认识，选择最符合您情况的选项。
      </h2>
      <h2 class="question-title" v-if="currentIndex >= '74'">
        九．请在下列个人基本信息调查表中按照您的实际状况填写或选择。
      </h2>

      <div v-if="currentQuestion" class="question-section">
        <span class="progress-text"
          >{{ currentIndex + 1 }}/{{ questions.length }}</span
        >
        <div class="progress-bar">
          <div class="progress" :style="{ width: progressWidth }"></div>
        </div>

        <div class="question">
          <h3
            class="question-title2"
            v-if="currentIndex < 42 || currentIndex > 53"
          >
            {{ currentQuestion.subject || currentQuestion.title }}
          </h3>

          <h3
            class="question-title2"
            v-if="currentIndex >= 42 && currentIndex < 48"
          >
            5.在您遇到困难情况时，能够从以下来源获得
            <span style="font-size: 1.2em">解决实际</span> 问题方面支持的程度:
          </h3>

          <h3
            class="question-title2"
            v-if="currentIndex > 47 && currentIndex < 54"
          >
            6.在您遇到困难情况时，能够从以下来源获得<span
              style="font-size: 1.2em"
            >
              安慰和关心</span
            >
            方面支持的程度:
          </h3>

          <h3
            class="question-title3"
            v-if="currentIndex == 42 || currentIndex == 48"
          >
            (1) 家人
          </h3>
          <h3
            class="question-title3"
            v-if="currentIndex == 43 || currentIndex == 49"
          >
            (2) 朋友
          </h3>
          <h3
            class="question-title3"
            v-if="currentIndex == 44 || currentIndex == 50"
          >
            (3) 同事
          </h3>
          <h3
            class="question-title3"
            v-if="currentIndex == 45 || currentIndex == 51"
          >
            (4) 公司
          </h3>
          <h3
            class="question-title3"
            v-if="currentIndex == 46 || currentIndex == 52"
          >
            (5) 工会
          </h3>
          <h3
            class="question-title3"
            v-if="currentIndex == 47 || currentIndex == 53"
          >
            (6) EAP员工帮助
          </h3>

          <!-- 单选 -->
          <div class="radio-label" v-if="currentIndex == '78'">
            <!-- 联系电话： -->
            <input
              type="text"
              v-model="phone"
              placeholder="请输入联系电话"
              style="position: relative; top: 0.23rem; left: 0.6rem"
            />
          </div>
          <div v-if="currentQuestion.type === 'radio'" class="options">
            <div
              v-for="(option, index) in parsedOptions"
              :key="index"
              class="option"
            >
              <input
                type="radio"
                :id="'option-' + currentQuestion.id + '-' + index"
                :value="option"
                v-model="answers[currentQuestion.id]"
                @change="saveAnswer"
                class="radio-input"
              />
              <label
                :for="'option-' + currentQuestion.id + '-' + index"
                class="radio-label"
              >
                {{ option }}
              </label>
            </div>
          </div>

          <!-- 多选 -->
          <div v-if="currentQuestion.type === 'checkbox'" class="options">
            <div
              v-for="(option, index) in parsedOptions"
              :key="index"
              class="option"
            >
              <input
                type="checkbox"
                :id="'checkbox-' + currentQuestion.id + '-' + index"
                :value="option"
                v-model="answers[currentQuestion.id]"
                @change="saveAnswer"
                class="checkbox-input"
              />
              <label
                :for="'checkbox-' + currentQuestion.id + '-' + index"
                class="checkbox-label"
              >
                {{ option }}
              </label>
            </div>
          </div>
          <!-- 下拉框 -->
          <div
            v-if="currentQuestion.type === 'select'"
            class="select-container"
          >
            <select
              v-model="answers[currentQuestion.id]"
              @change="saveAnswer"
              class="custom-select"
            >
              <option
                v-for="(option, index) in parsedOptions"
                :key="index"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </div>
          <input
            type="text"
            class="qita"
            v-if="currentIndex == '36' || currentIndex == '4'"
          />
          <textarea
            type="text"
            class="qita2"
            v-if="currentIndex == '41' || currentIndex == '39'"
            placeholder="请填写(选填)"
          />
        </div>
        <div class="navigation">
          <button
            @click="prevQuestion"
            :disabled="currentIndex === 0"
            class="nav-button"
          >
            上一题
          </button>
          <button
            v-if="currentIndex < questions.length - 1"
            @click="nextQuestion"
            class="nav-button"
          >
            下一题
          </button>
          <button v-else @click="submitAnswers" class="submit-button">
            提交
          </button>
        </div>
      </div>
      <div v-else class="loading">
        <p>加载中...</p>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      questions: [], // 所有题目
      currentIndex: 0, // 当前题目索引
      answers: {}, // 用户选择的答案
      phone: "",
    };
  },
  computed: {
    currentQuestion() {
      return this.questions[this.currentIndex];
    },
    progressWidth() {
      if (this.questions.length === 0) {
        return "0%";
      }
      return ((this.currentIndex + 1) / this.questions.length) * 100 + "%";
    },
    parsedOptions() {
      // 动态解析选项
      if (this.currentQuestion.answer) {
        // 如果存在 answer 字段，解析为 JSON
        try {
          const parsed = JSON.parse(this.currentQuestion.answer);
          return parsed.value || [];
        } catch (e) {
          console.error("无法解析 answer 字段：", this.currentQuestion.answer);
          return [];
        }
      } else if (this.currentQuestion.item) {
        // 如果存在 item 字段，提取 item.name
        return this.currentQuestion.item.map((option) => option.name);
      }
      return [];
    },
  },
  methods: {
    //开始测评

    // 加载问题
    async fetchQuestions() {
      const userId = localStorage.getItem("userId");
      if (!userId || userId == "undefined") {
        Toast("请先登录");
        setTimeout(() => {
          this.$router.push("/cp/minsheng/login");
        }, 1000);
        return;
      }
      try {
        const response = await fetch(
          "https://assess.eapchina.net/getQuestions877"
        );
        const data = await response.json();
        this.questions = Object.values(data.data).flat();
        this.questions.forEach((question) => {
          if (!question.type) {
            console.warn(`题目 ID ${question.id} 缺少 type 字段`);
          }
          // 初始化多选题为数组
          if (
            question.type === "checkbox" &&
            !Array.isArray(this.answers[question.id])
          ) {
            this.$set(this.answers, question.id, []);
          }
        });
        this.loadProgress(); // 加载保存的进度
      } catch (error) {
        console.error("加载题目失败：", error);
      }
    },

    // 保存答案
    saveAnswer() {
      localStorage.setItem("answers", JSON.stringify(this.answers));
    },

    // 加载进度
    loadProgress() {
      const savedAnswers = localStorage.getItem("answers");
      if (savedAnswers) {
        this.answers = JSON.parse(savedAnswers);
      }

      // 查找第一个未回答的问题
      this.currentIndex = this.questions.findIndex((question) => {
        const answer = this.answers[question.id];
        if (question.type === "checkbox") {
          return !answer || answer.length === 0; // 多选题未选中
        }

        // 判断单选和下拉框是否为空(区分 0 和未选择)
        if (question.type === "select" || question.type === "radio") {
          return answer === null || answer === undefined || answer === "";
        }

        // 其他题型的默认检查
        return answer === null || answer === undefined || answer === "";
      });

      // 如果所有问题都已回答，则将索引设置为最后一个问题
      if (this.currentIndex === -1) {
        this.currentIndex = this.questions.length - 1;
      }
    },
    // 下一题
    nextQuestion() {
      const currentAnswer = this.answers[this.currentQuestion.id];

      // 校验当前题目的答案是否已选择
      if (
        (this.currentQuestion.type === "radio" && !currentAnswer) || // 单选未选择
        (this.currentQuestion.type === "checkbox" &&
          (!currentAnswer || currentAnswer.length === 0)) || // 多选未选择
        (this.currentQuestion.type === "select" &&
          (currentAnswer === null ||
            currentAnswer === undefined ||
            currentAnswer === "")) // 下拉框未选择
      ) {
        Toast.fail("请选择选项后再继续！");
        return; // 阻止下一步
      }

      // 如果答案已选择，进入下一题
      if (this.currentIndex < this.questions.length - 1) {
        this.currentIndex++;
        this.saveAnswer(); // 保存进度
      }
    },

    // 上一题
    prevQuestion() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.saveAnswer(); // 保存进度
      }
    },

    submitAnswers() {
      Toast.fail("测评已结束");
    },
    // async submitAnswers() {
    //   // 检查未完成题目
    //   const unansweredIndex = this.questions.findIndex((question) => {
    //     const answer = this.answers[question.id];

    //     if (question.type === "checkbox") {
    //       return !answer || answer.length === 0; // 多选题未选中
    //     }

    //     // 单选和下拉框检查是否为空(区分 0 和未选择)
    //     if (question.type === "select" || question.type === "radio") {
    //       return answer === null || answer === undefined || answer === "";
    //     }

    //     // 其他题型的默认检查
    //     return answer === null || answer === undefined || answer === "";
    //   });

    //   if (unansweredIndex !== -1) {
    //     // 有未完成的题目，跳转到第一道未完成的题目
    //     this.currentIndex = unansweredIndex;
    //     Toast.fail(`请完成第 ${unansweredIndex + 1} 题后再提交！`);
    //     return;
    //   }

    //   // 数据处理
    //   const daan = {};
    //   for (const [questionId, answer] of Object.entries(this.answers)) {
    //     const question = this.questions.find((q) => q.id == questionId);
    //     if (!question) continue;

    //     if (question.lbassessment_id) {
    //       // 处理 `lb3519-9839` 格式
    //       const selectedOption = question.item.find(
    //         (option) => option.name === answer || option.id == answer
    //       );
    //       if (selectedOption) {
    //         daan[`lb${question.lbassessment_id}-${question.id}`] =
    //           selectedOption.id.toString();
    //       }
    //     } else {
    //       // 处理 `vote-0` 格式
    //       const voteKey = `vote-${questionId}`;
    //       if (Array.isArray(answer)) {
    //         // 多选题的答案拼接成逗号分隔的字符串
    //         daan[voteKey] = answer.join(",");
    //       } else {
    //         // 单选题或输入框答案直接使用
    //         daan[voteKey] = answer;
    //       }
    //     }
    //   }

    //   // 校验手机号逻辑
    //   const phoneRegex = /^1[3-9]\d{9}$/; // 简单的中国手机号正则
    //   if (daan["vote-29"] === "需要") {
    //     if (!this.phone || !phoneRegex.test(this.phone)) {
    //       Toast.fail("请输入正确的手机号码！");
    //       return; // 阻止提交
    //     }
    //     daan["vote-29"] = "需要:" + this.phone;
    //   }

    //   // if (daan["19"] === "其他"){
    //   //   daan["vote-29"] = "不需要";
    //   // }

    //   daan["userId"] = localStorage.getItem("userId");
    //   console.log("提交的答案：", daan);

    //   Toast.loading({
    //     message: "正在计算结果",
    //     forbidClick: true,
    //     duration: 0,
    //   });

    //   try {
    //     const response = await fetch(
    //       "https://assess.eapchina.net/saveTheAnswer",
    //       // "http://192.168.1.217:8081/assess/saveTheAnswer",

    //       {
    //         method: "POST",
    //         body: JSON.stringify(daan),
    //       }
    //     );
    //     const result = await response.json();
    //     Toast.success("提交成功");
    //     localStorage.setItem("voteLogId", result.voteLogId); // 清理缓存数据
    //     setTimeout(() => {
    //       this.$router.push("/CP/minsheng/answerOver");
    //     }, 1000);
    //     localStorage.removeItem("answers"); // 清理缓存数据
    //   } catch (error) {
    //     // Toast.fail("提交失败");
    //   }
    // },
  },

  created() {
    this.fetchQuestions();
  },
};
</script>

<style scoped>
.questionnaire {
  font-family: "Arial", sans-serif;
  max-width: 720px;
  margin: auto;
  padding: 20px;
  box-sizing: border-box;
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  /* background: #fff; */
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
}

.title {
  font-size: 1.8em;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.progress-bar {
  position: relative;
  background: #e0e0e0;
  height: 8px;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 20px;
}

.progress {
  background: #76c7c0;
  height: 100%;
}

.progress-text {
  font-size: 0.9em;
  margin-top: 8px;
  display: block;
  text-align: center;
}

.question-title {
  font-size: 1.2em;
  margin-bottom: 15px;
  color: #444;
  line-height: 30px;
  margin-bottom: 30px;
}
.question-title2 {
  font-size: 1em;
  color: #444;
  line-height: 30px;
}
.question-title3 {
  font-size: 1em;
  color: #444;
  line-height: 30px;
  margin: 10px 0;
}

.options {
  display: flex;
  flex-direction: column;
}

.option {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.radio-input,
.checkbox-input {
  margin-right: 10px;
}

.radio-label,
.checkbox-label {
  font-size: 1em;
  color: #555;
}

.select-container {
  margin-top: 10px;
}

.custom-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
  color: #555;
  background: #f9f9f9;
}

.navigation {
  margin-top: 20px;
  text-align: center;
}

.nav-button,
.submit-button {
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  border-radius: 4px;
  background-color: #76c7c0;
  color: white;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s;
}

.nav-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.nav-button:hover:not(:disabled),
.submit-button:hover {
  background-color: #5ba9a1;
}

@media (max-width: 768px) {
  .questionnaire {
    padding: 15px;
  }

  .title {
    font-size: 1.5em;
  }

  .nav-button,
  .submit-button {
    padding: 8px 16px;
    font-size: 0.9em;
  }
  .introduction {
    text-align: center;
    padding: 20px;
    max-width: 720px;
    margin: auto;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    height: 100%;
  }

  .introduction .content {
    font-size: 1em;
    line-height: 1.5;
    margin-bottom: 20px;
    text-align: left;
    color: #444;
  }

  .start-button {
    padding: 12px 24px;
    font-size: 1.1em;
    border: none;
    border-radius: 6px;
    background-color: #76c7c0;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .start-button:hover {
    background-color: #5ba9a1;
  }
}
.qita {
  position: relative;
  left: 70px;
  top: -30px;
}
.qita2 {
  margin-top: 10px;
  width: 100%;
  height: 80px;
  box-sizing: border-box;
  padding: 5px;
  border: #ccc 1px solid;
}
</style>
